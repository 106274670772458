<template>
    <div class="jellyPlanet">
        <!-- <h1>“果冻星球”音乐节</h1> -->
        <img src="https://img.youstarplanet.com/website/caseShow/CaseShow.png" alt="">
        <div class="navigation" @click="CaseShow"><span>案例展示 > “果冻星球”音乐节</span></div>
        <div class="content">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;        “果冻星球”是由YSP与深圳交响乐团合作孵化的音乐节类IP。旨在突出体现深圳多元、包容和融合的城市特性，2021首届音乐节以“交响的一百种滋味”为主题，内容以交响乐为主，结合多元音乐风格进行融合演绎，每场音乐会都以令人耳目一新的跨界组合表达：交响+诗朗诵、交响+流行、交响+曲艺、交响+电音，特色鲜明，气势恢宏。</p>
            
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return { }
    },
    methods: {
        CaseShow(){
            this.$router.push({path:`/caseshow`})
        }
    },
    async mounted() {
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.jellyPlanet{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .navigation{
        height: 48px;
        line-height: 48px;
        background-color: #F1F1F1;
        color: #8A8A8A;
        font-size: 14px;
        position: relative;
        top: -4px;
        span{
            display: block;
            width: 1270px;
            margin: 0 auto;
        }
    }
    .content{
        width: 1270px;
        margin: 0 auto;
        &>p{
            font-size: 16px;
            padding: 0 80px;
            margin-top: 60px;
            margin-bottom: 200px;
        }
        img{
            margin-top: 26px;
        }
    }
}
</style>